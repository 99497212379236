import React from 'react'
import { Link, useRouteError } from 'react-router-dom'
import { errBaba } from '../../assets'

const Error404 = () => {
    const error = useRouteError();

    return (
        <section style={{ width: "100%", height: "100vh", background: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "80%", textAlign: "center" }}>
                <div style={{ backgroundImage: `url(${errBaba})`, height: "400px", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                </div>
                <div style={{ marginTop: "-40px" }}>
                    <h3 style={{ fontSize: "40px", textTransform: "uppercase" }}>
                        {error.status} - {error.statusText}
                    </h3>
                    <Link to="/ads" style={{ color: "#FFFFFF", padding: "10px 30px", background: "#101010", margin: "20px 0", display: "inline-block", textDecoration: "none" }}>
                        Go to Home
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Error404