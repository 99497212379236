import React, { useState, useEffect } from 'react'
import { XR_Logo_Black, loadingBtn } from '../../assets'
import { Formik, Form } from "formik"
import FormikControl from '../../utils/Formik/FormikControl'

const CampaignsManage = () => {

    const [previewImage, setPreviewImage] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [randomID, setRandomID] = useState("");
    const [inputData, setInputData] = useState({
        responsiblePersonCM: "",
        projectNameCM: "",
        adsNameCM: "",
        callToActionCM: "",
        locationCM: "",
        languagesCM: "",
        targetingCM: "",
        ageGroupCM: "",
        adsHeadingCM: "",
        adsDescriptionCM: "",
        budgetAmountCM: "",
        budgetPeriodCM: "",
        fbPageNameCM: "",
        adsCreativesCM: ""
    })

    const initialValues = {
        responsiblePersonCM: "",
        projectNameCM: "",
        adsNameCM: "",
        callToActionCM: "",
        locationCM: "",
        languagesCM: "",
        targetingCM: "",
        ageGroupCM: "",
        adsHeadingCM: "",
        adsDescriptionCM: "",
        budgetAmountCM: "",
        budgetPeriodCM: "",
        fbPageNameCM: "",
        adsCreativesCM: ""
    }

    useEffect(() => {
        generateId();
    }, []);

    const onSubmit = async (values) => {
        setIsLoading(true);
        setInputData(values)

        setTimeout(() => {
            generatePDFN()
        }, 300)
    }

    const generatePDFN = () => {
        setIsLoading(false);
        setTimeout(() => {
            window.print();
        }, 300)
    };

    const generateId = () => {
        const randomId = Math.floor(Math.random() * 1000000000);
        setRandomID(randomId);
    };

    return (
        <>
            <header className='noprint'>
                <nav className="navbar">
                    <div className="logo">
                        <img src={XR_Logo_Black} alt="XRealty Logo" />
                    </div>
                    <h2>Campaigns Manager</h2>
                </nav>
            </header>

            <div className="main noprint">
                <div className="main-wrapper">
                    <h2 style={{ marginBottom: "1rem" }}>Enter all the details here!</h2>
                    <div className="main-form-container">

                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                        >
                            {
                                ({ formik, setFieldValue }) => {
                                    return (
                                        <Form>
                                            <div className="form-container">
                                                <FormikControl control="select" name="responsiblePersonCM" placeholder="Responsible Person" options={[
                                                    { key: "Select a responsible person", value: '' },
                                                    { key: 'Kumayl Merchant', value: 'Kumayl Merchant' },
                                                    { key: 'Shameem Pullengal', value: 'Shameem Pullengal' },
                                                    { key: 'Mohamed Zayed', value: 'Mohamed Zayed' },
                                                    { key: 'Sahil Sharma', value: 'Sahil Sharma' },
                                                    { key: 'Hiren Vaghasiya', value: 'Hiren Vaghasiya' },
                                                ]} />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="projectNameCM" placeholder="Project Name" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="adsNameCM" placeholder="Campaign Name" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="callToActionCM" placeholder="Call to Action(s) (comma seprated)" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="locationCM" placeholder="Location(s) (comma seprated)" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="languagesCM" placeholder="Languages(s) (comma seprated)" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="targetingCM" placeholder="Targeting" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="ageGroupCM" placeholder="Age Group" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="textarea" type="text" name="adsHeadingsCM" placeholder="Ads Heading(s) (comma seprated)" />
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="textarea" type="text" name="adsDescriptionCM" placeholder="Ads Description(s) (comma seprated)" />
                                            </div>
                                            <div className="form-container">
                                                <div className="inner-form-container">
                                                    <FormikControl control="input" type="text" name="budgetAmountCM" placeholder="Budget Amount" />
                                                    <FormikControl control="select" name="budgetPeriodCM" placeholder="Select a budget period" options={[
                                                        { key: "Select a budget period", value: '' },
                                                        { key: 'Daily', value: 'Daily' },
                                                        { key: 'Lifetime', value: 'Lifetime' },
                                                    ]} />
                                                </div>
                                            </div>
                                            <div className="form-container">
                                                <FormikControl control="input" type="text" name="fbPageNameCM" placeholder="FB Page Name" />
                                                <input
                                                    type="file"
                                                    multiple
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        let files = []

                                                        Array.from(e.target.files).forEach(file => {
                                                            let reader = new FileReader();
                                                            reader.onload = () => {
                                                                files.push(reader.result)
                                                            };
                                                            reader.readAsDataURL(file)
                                                        });

                                                        console.log(files)
                                                        setFieldValue("adsCreativesCM", files)
                                                        setPreviewImage(files)
                                                    }}
                                                />
                                            </div>

                                            <div className="form-container">
                                                <button type="submit">
                                                    {
                                                        isLoading
                                                            ? <img src={loadingBtn} alt="Infinity" className="loading" />
                                                            : <p style={{ padding: "16px 0" }}>Generate Ads Campaign PDF</p>
                                                    }
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>

                    </div>

                    {/* {previewImage?.map((item, key) => (<img key={key} src={item} style={{ width: "200px", margin: "10px" }} />))} */}
                </div>
            </div>

            <div className='page'>
                <div className="print-container">
                    <h1 style={{ marginBottom: "10px", fontSize: "1.6rem" }}>Ads Campaigns Manager ({randomID})</h1>
                    <hr />
                    <div style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)" }}>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Responsible Person: <span style={{ fontWeight: "normal" }}>{inputData.responsiblePersonCM}</span></h3>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>FB Page Name: <span style={{ fontWeight: "normal" }}>{inputData.fbPageNameCM}</span></h3>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Project Name: <span style={{ fontWeight: "normal" }}>{inputData.projectNameCM}</span></h3>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Campaign Name: <span style={{ fontWeight: "normal" }}>{inputData.adsNameCM}</span></h3>

                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Call to action(s): <span style={{ fontWeight: "normal" }}>{inputData.callToActionCM}</span></h3>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Location(s): <span style={{ fontWeight: "normal" }}>{inputData.locationCM}</span></h3>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Language(s): <span style={{ fontWeight: "normal" }}>{inputData.languagesCM}</span></h3>

                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Age group: <span style={{ fontWeight: "normal" }}>{inputData.ageGroupCM}</span></h3>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Ads Heading(s) (comma seprated): <span style={{ fontWeight: "normal" }}>{inputData.adsHeadingsCM}</span></h3>
                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>Ads Description(s) (comma seprated): <span style={{ fontWeight: "normal" }}>{inputData.adsDescriptionCM}</span></h3>

                        <h3 style={{ marginTop: "15px", fontSize: "1rem" }}>
                            Budget Amount:
                            <span style={{ fontWeight: "normal" }}> {inputData.budgetAmountCM}</span>
                            <span style={{ fontWeight: "bold" }}> ({inputData.budgetPeriodCM})</span>
                        </h3>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "auto", gridColumnGap: "10px", gridRowGap: "10px", marginTop: "20px" }}>
                        {previewImage?.map((item, key) => (<img key={key} src={item} style={{ width: "440px" }} />))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignsManage