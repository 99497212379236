import React from 'react'
import { Field } from 'formik'

const Select = (props) => {
    const { label, name, options, ...rest } = props

    return (
        <>
            {
                label ? <label htmlFor={name}>{label}</label> : null
            }
            <Field as="select" name={name} id={name} {...rest}>
                {
                    options?.map(option => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        )
                    })
                }
            </Field>
        </>
    )
}

export default Select