import React from 'react'
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import Error404 from './components/Error404/Error404';
import CampaignsManage from './components/CampaignsManage/CampaignsManage';

const App = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

const appRouter = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <Error404 />,
        children: [
            {
                path: '/',
                element: <CampaignsManage />
            },
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);