import React from 'react'
import { Field } from 'formik'

const Textarea = (props) => {
    const { label, name, ...rest } = props
    return (
        <>
            {
                label ? <label htmlFor={name}>{label}</label> : null
            }
            <Field as='textarea' name={name} id={name} {...rest} />
        </>
    )
}

export default Textarea