import React from 'react'
import { Field } from 'formik'

const Input = (props) => {
    const { label, name, ...rest } = props

    return (
        <>
            {
                label ? <label htmlFor={name}>{label}</label> : null
            }
            <Field name={name} id={name} {...rest} />
        </>
    )
}

export default Input